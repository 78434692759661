$(document).ready(function () {
	$(window).scroll(function () {
			$('#stickyNav').toggleClass("header-fixed animated fadeInDown", ($(window).scrollTop() > 100));
	 });
});

// Fixed Nav
jQuery(document).ready(function ($) {  
  // Active Nav Link
  $('nav ul li a').click(function(){
		$('nav ul li a').removeClass('active');
		$(this).addClass('active');
	});
})


